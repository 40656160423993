import { forwardRef, Fragment } from 'react';
import { classNames } from '../../utils/classNames';
import { removeMarkdown } from '../../utils/removeMarkdown';

import ExpandButton from './ExpandButton';
import Markdown from './Markdown';

const DesktopTemplateCard = forwardRef(
	(
		{
			isFirst,
			isExpanded,
			sectionName,
			contactContent,
			isContactSection,
			isProjectsSection,
			isCardPopupOpen,
			image,
			header,
			text,
			displayedText,
			isTruncated,
			toggleCard,
			handleOpenCardPopup,
			isDesktop,
		},
		ref
	) => {
		return (
			<div
				ref={ref}
				key={isExpanded ? 'expanded' : 'collapsed'}
				onClick={() => (!isDesktop || !isFirst) && toggleCard(header)}
				className={classNames(
					'w-full h-fit flex flex-col gap-[22px]',
					'p-[18px] rounded-[26px]',
					isFirst ? 'mb-[22px] md:mb-6' : 'mb-0',
					'shadow-cardShadow bg-backgroundColor-secondary',
					'transition-all duration-300 ease-in-out'
				)}
			>
				<Fragment>
					{image && <img src={image} alt='' className='rounded-2xl' />}

					<h4 className='font-bold text-[23px] md:text-[26px] leading-[28px] md:leading-[36px]'>
						{isContactSection ? removeMarkdown(contactContent.header) : removeMarkdown(header)}
					</h4>
				</Fragment>

				{text && (
					<Fragment>
						{isContactSection ? (
							<Fragment>
								<p className='font-medium text-sm leading-[25px] whitespace-normal'>{contactContent.beforeText}</p>

								<ul className='flex flex-col gap-2 my-3'>
									{contactContent.contacts.map(({ icon, link, name, text }, index) => (
										<li key={index}>
											<a
												href={link}
												target={name !== 'Phone' ? '_blank' : '_self'}
												rel='noopener noreferrer'
												className='text-accent flex items-center gap-2'
											>
												<img src={icon} alt={`${name} Icon`} className='w-[14px] h-[14px]' />

												<div className='flex items-center gap-1'>
													<span className='font-semibold'>{name}:</span>
													<span>{text}</span>
												</div>
											</a>
										</li>
									))}
								</ul>

								<p className='font-medium text-sm'>{contactContent.afterText}</p>
							</Fragment>
						) : (
							<span className='font-medium text-sm leading-[25px] whitespace-normal'>
								<Markdown
									isFirst={isFirst}
									isExpanded={isExpanded}
									isCardPopupOpen={isCardPopupOpen}
									isProjectsSection={isProjectsSection}
									displayedText={displayedText}
								/>
							</span>
						)}
					</Fragment>
				)}

				{isDesktop && !isContactSection && isTruncated && (isFirst || isExpanded) ? (
					<button
						className={classNames(
							'w-full h-[30px]',
							'text-xs text-input-placeholder font-bold',
							'ml-auto -mt-4 rounded-[30px] bg-[#E9F0FF]'
						)}
						onClick={() => handleOpenCardPopup(header)}
					>
						Read More
					</button>
				) : (
					!isDesktop &&
					!isContactSection &&
					isTruncated &&
					isExpanded && (
						<button
							className={classNames(
								'w-full h-[30px]',
								'text-xs text-input-placeholder font-bold',
								'ml-auto -mt-4 rounded-[30px] bg-[#E9F0FF]'
							)}
							onClick={() => handleOpenCardPopup(header)}
						>
							Read More
						</button>
					)
				)}

				<div className='flex items-center justify-between'>
					{sectionName && (
						<div className='flex items-center gap-[6px]'>
							<div className='w-[17px] h-[17px] rounded-full bg-accentGradient' />
							<span className='text-[12px] leading-[16px]'>{sectionName}</span>
						</div>
					)}

					{(!isDesktop || !isFirst) && <ExpandButton sectionName={sectionName} isExpanded={isExpanded} />}
				</div>
			</div>
		);
	}
);

export default DesktopTemplateCard;
