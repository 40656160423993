import { Fragment } from 'react';
import { classNames } from '../../utils/classNames';
import { removeMarkdown } from '../../utils/removeMarkdown';

import Popup from './Popup';
import Markdown from '../template-cards/Markdown';
import { ReactComponent as CloseIcon } from '../../assets/navigation/close.svg';

import '../../styles/custom-scrollbar.scss';

const CardPopup = ({
	isOpen,
	handleClose,
	isFirst,
	isExpanded,
	isCardPopupOpen,
	isContactSection,
	isProjectsSection,
	contactContent,
	cardContent,
	className,
	zIndex,
}) => {
	return (
		<Popup isOpen={isOpen} handleClose={handleClose} className={className} zIndex={zIndex}>
			<div className='relative p-[18px] pb-[26px] rounded-[28px] shadow-cardShadow bg-backgroundColor-secondary'>
				<button className='absolute top-8 right-11' aria-label='Close' onClick={handleClose}>
					<CloseIcon className='fill-white' />
				</button>

				<div
					className={classNames(
						'w-full h-full max-h-[calc(100vh-80px)]',
						'flex flex-col gap-[22px] overflow-y-auto',
						'overflow-y-auto bg-backgroundColor-secondary',
						'transition-height duration-300 ease-in-out z-[80]',
						'pr-1.5 rounded-tl-[20px] rounded-tr-[20px] rounded-bl-[20px] scrollbar-custom'
					)}
				>
					<img src={cardContent.image} alt='' className='rounded-[16px] max-h-[259px] h-full object-cover' />

					<h3 className='font-bold text-[26px] leading-[36px]'>{removeMarkdown(cardContent.header)}</h3>

					{isContactSection ? (
						<Fragment>
							<p className='font-medium text-sm leading-[25px] whitespace-normal'>{contactContent.beforeText}</p>

							<ul className='flex flex-col gap-2 my-3'>
								{contactContent.contacts.map(({ icon, link, name, text }, index) => (
									<li key={index}>
										<a
											href={link}
											target={name !== 'Phone' ? '_blank' : '_self'}
											rel='noopener noreferrer'
											className='text-accent flex items-center gap-2'
										>
											<img src={icon} alt={`${name} Icon`} className='w-[14px] h-[14px]' />

											<div className='flex items-center gap-1'>
												<span className='font-semibold'>{name}:</span>
												<span>{text}</span>
											</div>
										</a>
									</li>
								))}
							</ul>

							<p className='font-medium text-sm'>{contactContent.afterText}</p>
						</Fragment>
					) : (
						<span className='font-medium text-sm leading-[25px] whitespace-normal'>
							<Markdown
								isFirst={isFirst}
								isExpanded={isExpanded}
								isCardPopupOpen={isCardPopupOpen}
								isProjectsSection={isProjectsSection}
								displayedText={cardContent.text}
							/>
						</span>
					)}

					<div className='flex items-center justify-end'>
						<button
							onClick={handleClose}
							className='w-[107px] h-[30px] text-xs text-input-placeholder font-medium rounded-[30px] bg-[#E9F0FF]'
						>
							Close
						</button>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default CardPopup;
